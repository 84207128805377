import React, { Fragment, useEffect, useState, useRef, useCallback } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import { PlusCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast, ToastContainer } from "react-toast";
import { Await, Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import { useFormik } from "formik";
import { profileSchema } from "../../../schemas";
import { Dialog, Transition, Switch } from "@headlessui/react";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import moment from 'moment';
import Chart from "react-apexcharts";
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import FSLoader from "../../../components/FSLoader";
import WhopServices from "../../../ApiServices/WhopServices";
import Table from "../../../components/tables/table";
import { matrics_trade_history_columns, matrics_trade_live_columns } from "../../../components/tables/tableheader";
import { utils, writeFile } from 'xlsx';
import Editor from '@monaco-editor/react';
import ConfirmationModal from "../../../components/ConfirmationModal";
import AccMetrixHistory from "./AccMetrixHistory";

/* const tabs = [
  { name: '123456', current: false },
  { name: '345267', current: false },
  { name: '853765', current: true },
  { name: '972537', current: false },
  { name: '400757', current: false },
] */

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
function userStatusToggle(...classes) {
  return classes.filter(Boolean).join(" ");
}

const mstats = [
  { name: "Total Position", stat: '', previousStat: '', change: 'LOSS', changeType: '' }, //acc_position
  { name: 'MAX DrawDown', stat: '', previousStat: '', change: 'LOSS', changeType: '' },
  { name: "Floating PNL", stat: '', previousStat: '', change: 'PROFIT', changeType: '' }, // unrealized
  { name: "Total PNL", stat: '', previousStat: '', change: 'PROFIT', changeType: '' }, // trade history - profit colums sum-up
  { name: "Today's PNL", stat: '0 USD', previousStat: '', change: 'PROFIT', changeType: '' }, // relized_pnl
  { name: "Cummulative Lotsize", stat: '0', previousStat: '', change: '', changeType: '' } // relized_pnl
]

const AccMetrix = () => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const uid = queryParams.get('uid');
  const name = queryParams.get('name');
  const accid = queryParams.get('accid');


  const navigate = useNavigate();
  const [tabs, setTabs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState(null);
  const [accInfo, setAccInfo] = useState(null);
  // const [tradeHistory, setTradeHistory] = useState([]);
  const [tradeLive, setTradeLive] = useState([]);
  const [three_stats, setMatStats] = useState(mstats);
  const [currentDD, setCurrentDD] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [defaultAccID, setDefaultAccID] = useState(queryParams.get('accid'));
  const [accStatus, setAccStatus] = useState(null);
  const [currentTable, setTable] = useState(0);
  const [emptyCount, setEmptyCount] = useState(0);
  const [open, setOpen] = useState(false);
  const editorRef = useRef(null);
  const [configData, setConfigData] = useState("");
  const [configDataManual, setConfigDataManual] = useState("");
  const [currentConfigUser, setCurrentConfigUser] = useState(null);
  const [paramsData, setParamsData] = useState(null);
  // const [pageCount, setPageCount] = useState(0);
  // const [isCustomPagination, setIsCustomPagination] = useState(true);
  // const [customPageNo, setCustomPageNo] = useState(1);

  const [stopTrading, setStopTrading] = useState(false);
  const [closeTrading, setCloseTrading] = useState(false);
  const [accBanned, setAccBanned] = useState(false);
  const [lamId, setLAMId] = useState(false);


  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    lam_id: null,
  });

  const handleTabChange = (e) => {
    //console.log("ACC ID: ", e.target.value);
    setCurrentTab(e.target.value);
    //setIsLoading(true);
    //setLoading(true);
    //getAllMatrics(uid, name, e.target.value);
  };

  // useEffect(() => {

  //   const intervalId = setInterval(() => {
  //     if (currentTab) {
  //       getAllMatrics(queryParams.get('uid'), queryParams.get('name'), queryParams.get('accid'));
  //     }
  //   }, 5000);
  //   return () => clearInterval(intervalId);

  // }, [isLoading]);

  useEffect(() => {
    let timeoutId;
    
    const fetchMetrics = async () => {
   
      if (currentTab && window.location.pathname === "/accm") {
        try {
          const success = await getAllMatrics(
            queryParams.get('uid'),
            queryParams.get('name'),
            queryParams.get('accid')
          );
  
          if (success) {
            // Schedule the next call after 5 seconds
            if (window.location.pathname === "/accm") {
              console.log('fetchMetrics=>',window.location.pathname)
            timeoutId =  setTimeout(fetchMetrics, 5000); // 5 seconds
            }
          } else {
            console.log("Fetch failed, stopping recursive calls.");
          }
        } catch (e) {
          console.log("Error in recursive call, stopping further execution:", e);
        }
      }
    };
    if (window.location.pathname === "/accm") {
    // Start the recursive fetching
    fetchMetrics();
    }
  
    // Cleanup logic when the component unmounts or `isLoading` changes
    // return () => {
    //   setIsLoading(false); // Optional: Cancel loading state
    // };

    // return () => {
    //   if (timeoutId) clearTimeout(timeoutId); // Stop background calls when unmounting
    // };
  }, [isLoading, currentTab]);

  useEffect(() => {
    
    if (window.location.pathname === "/accm") {

      getAllMatrics(queryParams.get('uid'), queryParams.get('name'), queryParams.get('accid'));
    };
  }, [])

  // useEffect(() => {
  //   // Set up a timer to call getAllAccounts every 10 seconds
  //   if (queryParams.get('uid') || queryParams.get('name') || queryParams.get('accid')) {
  //     const intervalId = setInterval(async () => {
  //       getAllMatrics(queryParams.get('uid'), queryParams.get('name'), queryParams.get('accid'));
  //     }, 5000); // 5 seconds in milliseconds

  //     // Clean up the timer on component unmount
  //     return () => clearInterval(intervalId);
  //   }
  // }, [queryParams.get('uid'), queryParams.get('name'), queryParams.get('accid')]);

  useEffect(() => {
    let timeoutId;
    const fetchAllMetrics = async () => {
      
      if ((queryParams.get('uid') || queryParams.get('name') || queryParams.get('accid')) && window.location.pathname === "/accm") {
        try {
          const success = await getAllMatrics(
            queryParams.get('uid'),
            queryParams.get('name'),
            queryParams.get('accid')
          );
  
          if (success) {
            // Schedule the next call after 5 seconds
            if(window.location.pathname === "/accm"){
            timeoutId = setTimeout(fetchAllMetrics, 5000); // 5 seconds
            }
          } else {
            console.log("Fetch failed, stopping recursive calls.");
          }
        } catch (e) {
          console.error("Error in recursive call:", e);
        }
      }
    };
  
    // Start the recursive fetching
    if (window.location.pathname === "/accm") {
    fetchAllMetrics();
    }
  
    // Cleanup logic to stop recursive calls when the component unmounts
    // return () => {
    //   setIsLoading(false); // Optional: Reset loading state or stop other actions
    // };
    // return () => {
    //   if (timeoutId) clearTimeout(timeoutId); // Stop background calls when unmounting
    // };
  }, [queryParams.get('uid'), queryParams.get('name'), queryParams.get('accid')]);

  const getAllMatrics = async (uid, name, accid) => {
    // Ensure uid, name, and accid are valid before constructing payload
    if (!uid || !name || !accid) {
      console.error('uid, name, or accid is missing or invalid.');
      setIsLoading(false);
      setLoading(false);
      return;
    }
    let payload = `?uid=${uid}&name=${name}&accid=${accid}&page=1`;
    try {
      const res = await MasterServices.getAllMatrics(payload);
      if (res.status) {
        //console.log(res.data.data);
        setTabs(res?.data?.data?.all_accounts);
        //tabs.find(tab => tab.current).name
        setCurrentTab(res?.data?.data?.all_accounts?.find(tab => tab.current).name);
        setAccInfo(res?.data?.data?.account_info);

        setStopTrading(res?.data?.data?.account_info?.license_account_map_relation?.lam_is_stopped_trading);
        setCloseTrading(res?.data?.data?.account_info?.license_account_map_relation?.lam_is_closed_all_trade);
        setAccBanned(res?.data?.data?.account_info?.license_account_map_relation?.lam_is_banned);
        setLAMId(res?.data?.data?.account_info?.license_account_map_relation?.lam_id);
        // setPageCount(res?.data?.data?.trade_history_pagination?.total_pages);
        setParamsData(res?.data?.data?.params_data);
        // if (tradeHistory.length != res?.data?.data?.trade_history.length) {
        //   setTradeHistory(res?.data?.data?.trade_history);
        // }
        setTradeLive(res?.data?.data?.live_trades);
        /* if(res?.data?.data?.live_trades.length > 0){
          setTradeLive(res?.data?.data?.live_trades);
        }else{
          if (emptyCount < 2) {
            // If less than 3 consecutive empty responses, increment emptyCount
            setEmptyCount(emptyCount + 1);
          } else {
            // If 3 consecutive empty responses, set data state to empty array
            setTradeLive([]);
            // Reset emptyCount
            setEmptyCount(0);
          }
        } */
        setChartData(res?.data?.data?.chart_data);
        setAccStatus(res?.data?.data?.check_status);

        let tempStats = mstats;
        tempStats[0].stat = res?.data?.data?.account_info?.mua_acc_positions ? res?.data?.data?.account_info?.mua_acc_positions : 0;
        tempStats[1].stat = (res?.data?.data?.max_dd ? res?.data?.data?.max_dd : 0) + "%"; //currentDD < parseFloat(res?.data?.data?.history_dd) ? currentDD + "%" : parseFloat(res?.data?.data?.history_dd).toFixed(2)  + "%";
        tempStats[2].stat = res?.data?.data?.account_info?.mua_acc_unreal_pnl ? res?.data?.data?.account_info?.mua_acc_unreal_pnl : 0;
        tempStats[3].stat = res?.data?.data?.trade_history?(parseFloat(eval(calculateTotalByKey(res?.data?.data?.trade_history, "mth_order_profit") + calculateTotalByKey(res?.data?.data?.trade_history, "mth_order_comm") + calculateTotalByKey(res?.data?.data?.trade_history, "mth_order_swap"))).toFixed(2)):0;
        tempStats[4].stat = res?.data?.data?.account_info?.mua_acc_real_pnl ? parseFloat(res?.data?.data?.account_info?.mua_acc_real_pnl).toFixed(2) : 0;
        tempStats[5].stat = res?.data?.data?.liveTradeTotLot ? parseFloat(res?.data?.data?.liveTradeTotLot).toFixed(2) : 0;
        setMatStats(tempStats);
        calcLiveMaxDD(res?.data?.data?.account_info);
        //setProductList(res.data.data);
      }
      setIsLoading(false);
      setLoading(false);
      return true;
    } catch (e) {
      console.log(e, "error in getAllMatrics");
      setIsLoading(false);
      setLoading(false);
      // toast.error("Oops, Something went wrong...");
      return false;
    }
  }
  // useEffect(() => {
  //   getAllTradeHistory(uid, name, accid);
  // }, [customPageNo])
  const getAllTradeHistory = async (uid, name, accid) => {
    // Ensure uid, name, and accid are valid before constructing payload
    if (!uid || !name || !accid) {
      console.error('uid, name, or accid is missing or invalid.');
      // setIsLoading(false);
      // setLoading(false);
      return;
    }
    // let payload = `?uid=${uid}&name=${name}&accid=${accid}&page=${parseInt(customPageNo)}`;
    try {
      // const res = await MasterServices.getTradeHistory(payload);
      // if (res.status) {
      //   // setPageCount(res?.data?.data?.trade_history_pagination?.total_pages);
      //   setTradeHistory(res?.data?.data?.trade_history);

      // }
    } catch (e) {
      console.log(e, "error in getAllMatrics");
      // toast.error("Oops, Something went wrong...");
    }
  }

  const calculateTotalByKey = (arr, key) => {
    // Use reduce to sum up the values of the specified key
    const total = arr?.reduce((acc, obj) => acc + obj[key], 0);
    return total;
  }

  const calcLiveMaxDD = (data) => {

    let params = 0;
    if (parseFloat(data?.mua_acc_equity) > parseFloat(data?.mua_acc_balance)) {
      //current_dd = equity / unnreeal
      params = parseFloat(data?.mua_acc_equity).toFixed(2);
    } else {
      //current_dd = balance / unnreeal
      params = parseFloat(data?.mua_acc_balance).toFixed(2);
    }

    let tempDD = parseFloat(((data?.mua_acc_unreal_pnl / params) * 100)).toFixed(2); // % - float
    if (currentDD > tempDD) {
      setCurrentDD(currentDD);
    } else {
      setCurrentDD(tempDD);
    }

  }

  // This function is getting calcuted by backennd in matrics controller ***
  function calculateMaxDrawdownUsingBalanceLogs(tradeHistory) {
    let maxDrawdown = 0;
    let peak = tradeHistory[0].acc_balance; // Initial peak value
    let trough = tradeHistory[0].acc_balance; // Initial trough value

    for (let i = 1; i < tradeHistory.length; i++) {
      const balance = tradeHistory[i].acc_balance;

      if (balance > peak) {
        peak = balance; // Update peak if current balance is greater
        trough = peak; // Reset trough to current peak
      } else if (balance < trough) {
        trough = balance; // Update trough if current balance is lower
        const drawdown = (peak - trough) / peak; // Calculate drawdown percentage
        maxDrawdown = Math.max(maxDrawdown, drawdown); // Update maxDrawdown if needed
      }
    }

    return maxDrawdown;
  }

  const handleExport = (excel_type) => {
    // if (!tradeHistory || tradeHistory.length === 0) {
    //   toast.error("Trade History is empty!");
    //   return;
    // }
    // if (tradeHistory?.length > 0) {
    //   let newSheetdata = [];
    //   tradeHistory.map((obj, index) => {
    //     const newData = {
    //       SN: parseInt(index) + 1,
    //       mth_order_ticket: obj?.mth_order_ticket || '-',
    //       mth_order_open_time: obj?.mth_order_open_time || '-',
    //       mth_order_type: obj?.mth_order_type == 0 ? 'BUY' : 'SELL' || '-',
    //       mth_order_lots: obj?.mth_order_lots || '-',
    //       mth_order_symbol: obj?.mth_order_symbol || '-',
    //       mth_order_open_price: obj?.mth_order_open_price || '-',
    //       mth_order_close_price: obj?.mth_order_close_price || '-',
    //       mth_order_close_time: obj?.mth_order_close_time || '-',
    //       mth_order_sl: obj?.mth_order_sl || '-',
    //       mth_order_tp: obj?.mth_order_tp || '-',
    //       mth_order_profit: obj?.mth_order_profit || '-',
    //       mth_order_comm: obj?.mth_order_comm || '-',
    //       mth_order_swap: obj?.mth_order_swap || '-',
    //       mth_scp_sum: obj?.mth_scp_sum || '-',
    //     }
    //     newSheetdata.push(newData);
    //   });

    //   let currentDate = new Date();
    //   let fileName = 'trade_history_' + moment(currentDate).format("DD-MM-YYYY") + '.' + excel_type;
    //   const headings = [['SN', 'Ticket', 'Open Time', 'Type', 'Lots', 'Symbol', 'Open Price ($)', 'Close Price ($)', 'Close Time', 'SL', 'TP', 'Profit', 'Comission', 'Swap', 'Net PNL']];
    //   const wb = utils.book_new();
    //   const ws = utils.json_to_sheet([]);
    //   utils.sheet_add_aoa(ws, headings);
    //   utils.sheet_add_json(ws, newSheetdata, { origin: 'A2', skipHeader: true });
    //   utils.book_append_sheet(wb, ws, 'Report');
    //   writeFile(wb, fileName);
    // } else {
    //   toast.error("Data Not Found!");
    // }
  }

  const addParamConfig = () => {
    if (accInfo && accInfo?.mua_acc_param_config && accInfo?.mua_acc_param_config != null) {
      // alert(data?.mt_user_account[0]?.mua_acc_param_is_json)
      if (accInfo?.mua_acc_param_is_json) {
        setConfigData(JSON.stringify(JSON.parse(accInfo?.mua_acc_param_config), null, 2));
      } else {
        setConfigData(JSON.stringify(accInfo?.mua_acc_param_config, null, 2));
      }
    }
    if (accInfo && accInfo?.mua_acc_param_config_manual && accInfo?.mua_acc_param_config_manual != null) {
      if (accInfo?.mua_acc_param_is_json) {
        setConfigDataManual(JSON.stringify(JSON.parse(accInfo?.mua_acc_param_config_manual), null, 2));
      } else {
        setConfigDataManual(JSON.stringify(accInfo?.mua_acc_param_config_manual, null, 2));
      }
    }
    setOpen(true);
  }

  const updateUserConfig = async (e) => {

    /* if(configData != null){}else{} */
    if (!accInfo) {
      toast.error("Something went wrong...");
      return;
    }

    let { data, status } = await WhopServices.updateConfigData({ mua_id: queryParams.get('accid'), data: configDataManual });
    if (status === 200) {
      if (data.status) {
        toast.success(data.message);
        getAllMatrics(queryParams.get('uid'), queryParams.get('name'), queryParams.get('accid'));
      }
      setOpen(false);
    } else {
      toast.error(data.message);
    }

  }

  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = editor;
    console.log("editor.getValue()=>", editor.getValue())
    editor.onDidChangeModelContent(() => {
      // Update configData state when the editor content changes
      setConfigDataManual(editor.getValue());
    });
  }
  const onDeleteOpen = (pk_id, acc_status, type) => {
    let title = "Are you sure you want to modify?";
    if (type == "lam_is_stopped_trading") {
      title = "Are you sure you want to " + (confirmationModal.acc_status ? "Re-Start Trading" : "Stop Trading") + " this Account?";
    } else if (type == "lam_is_closed_all_trade") {
      title = "Are you sure you want to " + (confirmationModal.acc_status ? "Open All Trading" : "Close All Trading") + " this Account?";
    } else {
      title = "Are you sure you want to " + (confirmationModal.acc_status ? "Un-Ban (Revoke)" : "Ban") + " this Account?";
    }
    setConfirmationModal({ pk_id, acc_status, type, status: true, title });
  };
  const onStatusUpdate = (pk_id, acc_status, type) => {

    let payload = {
      table_name: "license_account_map",
      table_field: "lam_id",
      table_field_val: pk_id,
      table_status_field: type, //"lam_is_banned",
      table_status_val: acc_status ? false : true,
      table_text: "Account",
      deleted_by_key: "lam_updated_by",
      delete_active_txt: "Acc Status" //acc_status ? " Un-Baned" : " Ban",
    }
    MasterServices.removeMaster(payload).then((res) => {
      const { data, status, message } = res.data
      if (status) {
        getAllMatrics(queryParams.get('uid'), queryParams.get('name'), queryParams.get('accid'));
        toast.success(message);
      } else {
        toast.error(message)
      }
    })
  }

  return (
    <>
      {!loading ? (

        <div>
          <ConfirmationModal
            title={confirmationModal?.title ? confirmationModal?.title : "Are you sure you want to modify settings?"}
            confirmationButtonText="Yes"
            open={confirmationModal.status}
            onDelete={() =>
              onStatusUpdate(
                confirmationModal.pk_id,
                confirmationModal.acc_status,
                confirmationModal.type
              )
            }
            setOpen={setConfirmationModal}
          />
          <div className="overflow-hidden bg-white shadow sm:rounded-lg mb-2">
            <div className="flex justify-between items-center">
              <div className="px-4 py-6 sm:px-6">
                <h3 className="text-base font-semibold leading-7 text-gray-900">Account MetriX</h3>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Detailed Account MetriX of <b>{accInfo?.mua_acc_name}</b>.</p>
              </div>
              <div className="px-8 py-6 sm:px-6">
                <div className="flex justify-between items-center">
                  <button type="button" class="rounded bg-slate-100 px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-5 transition ease-in-out duration-150 cursor-pointer leading-6 mr-2"
                    onClick={(e) => {
                      setConfigData('');
                      addParamConfig(accInfo);
                    }}
                  >
                    Add Config
                  </button>

                  <div className="px-2 py-2 rounded-md backdrop-blur-sm bg-slate-100 flex border">
                    <div className=" mr-5  flex-row items-center align-middle text-center justify-center">
                      <span className="text-xs font-medium">Stop Trade</span><br />
                      <Switch
                        checked={stopTrading}
                        onChange={() => { setStopTrading(!stopTrading); onDeleteOpen(lamId, stopTrading, 'lam_is_stopped_trading') }}
                        className={userStatusToggle(
                          stopTrading ? "bg-[#00d4f6]" : "bg-gray-200",
                          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                        )}
                      >
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className={userStatusToggle(
                            stopTrading ? "translate-x-5" : "translate-x-0",
                            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                          )}
                        />
                      </Switch>
                    </div>
                    <div className=" mr-5  flex-row items-center align-middle text-center justify-center">
                      <span className="text-xs font-medium">Close ALl Trade</span><br />
                      <Switch
                        checked={closeTrading}
                        onChange={() => { setCloseTrading(!closeTrading); onDeleteOpen(lamId, closeTrading, 'lam_is_closed_all_trade') }}
                        className={userStatusToggle(
                          closeTrading ? "bg-[#00d4f6]" : "bg-gray-200",
                          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                        )}
                      >
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className={userStatusToggle(
                            closeTrading ? "translate-x-5" : "translate-x-0",
                            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                          )}
                        />
                      </Switch>
                    </div>
                    <div className=" flex-row items-center align-middle text-center justify-center">
                      <span className="text-xs font-medium items-center align-middle mb-4">Ban (Is Banned)</span><br />
                      <Switch
                        checked={accBanned}
                        onChange={() => { setAccBanned(!accBanned); onDeleteOpen(lamId, accBanned, 'lam_is_banned') }}
                        className={userStatusToggle(
                          accBanned ? "bg-[#00d4f6]" : "bg-gray-200",
                          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 "
                        )}
                      >
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className={userStatusToggle(
                            accBanned ? "translate-x-5" : "translate-x-0",
                            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                          )}
                        />
                      </Switch>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="border-t border-gray-100">

              <div className="acc-headig">

                <div className="relative border-b border-gray-200 pb-5 sm:pb-0 px-4 py-6 sm:px-6">
                  <div className="md:flex md:items-center md:justify-between">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">Select The Account</h3>
                    <div className="mt-3 flex md:absolute md:right-0 md:top-3 md:mt-0">
                    </div>
                  </div>
                  {
                    tabs && tabs.length > 0 ? (
                      <div className="mt-4">
                        <div className="sm:hidden">
                          <label htmlFor="current-tab" className="sr-only">
                            Select a tab
                          </label>
                          <select
                            id="current-tab"
                            name="current-tab"
                            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#0172fd]"
                            value={currentTab}
                            onChange={handleTabChange}
                          >
                            {tabs.map(tab => (
                              <option key={tab.name} value={tab.name}>{tab.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="hidden sm:block">
                          <nav className="-mb-px flex space-x-8">
                            {tabs.map(tab => (
                              <a
                                key={tab.name}
                                className={classNames(
                                  tab.name === currentTab ? 'border-[#0172fd] text-[#0172fd]' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                  'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium cursor-pointer'
                                )}
                                href={"/accm?uid=" + uid + "&name=" + name + "&accid=" + tab.name}
                                aria-current={tab.name === currentTab ? 'page' : undefined}
                              >
                                {tab.name}
                              </a>
                            ))}
                          </nav>
                        </div>
                      </div>
                    ) : null
                  }
                </div>

                <div className="lg:m-4 lg:p-4 md:m-4 md:p-4 sm:p-1 sm:m-1">
                  <Chart
                    type="area"
                    series={[
                      {
                        name: "Balance",
                        data: chartData, //[1000,2000,3000,1000,500]
                      }
                    ]
                    }
                    options={{
                      title: {
                        text: "Trade History",
                        style: { fontSize: 15 }
                      },
                      colors: ['#00D0C2'],
                      stroke: { width: 2, curve: 'smooth' },
                      dataLabels: {
                        enabled: false
                      },
                      ///fill:{opacity:1, type:'solid'},
                      xaxis: {
                        title: {
                          text: "Trades",
                          style: { fontSize: 15, color: '#0172fd' } //0172fd-00f4e4-00d4f6
                        },
                        //min: 1,
                        //max: 48,
                        //range: 25,
                        //stepSize: 2,
                        type: "category",
                        labels: {
                          show: false,
                          trim: true,
                          rotateAlways: false,
                          offsetX: 10,
                          hideOverlappingLabels: true,
                        },
                        tickPlacement: 'on',
                        axisTicks: {
                          show: true,
                          borderType: 'solid',
                          color: '#78909C',
                          height: 6,
                          offsetX: 0,
                          offsetY: 0
                        },
                        //categories:getDatayear
                      },
                      yaxis: {
                        title: {
                          text: "PNL",
                          style: { fontSize: 15 }
                        }
                      }
                    }
                    }
                    height={400} // Here, you set the chart height explicitly
                    style={{ width: "100%" }} // Optional: You can control width as well
                  >
                  </Chart>
                </div>



                <div className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8" >
                  <dl className="mx-auto grid grid-cols-1 gap-px sm:grid-cols-2 lg:grid-cols-2">
                    <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                      <div class="lg:col-start- lg:row-end-1">
                        <h2 class="text-base font-semibold leading-7 text-[#0172fd] mb-2">Parameter Config</h2>
                        <div class="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
                          <table class="min-w-full">
                            <tbody>

                              {/* pair_filter */}
                              <tr>
                                <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                  <div className="flex">
                                    <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Pair Filter : </p>
                                    <p class="text-gray-600 whitespace-no-wrap font-medium">{paramsData?.pc_pair_filter ? paramsData?.pc_pair_filter : "N/A"}</p>
                                  </div>
                                </td>
                                <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                  <div className="flex">
                                    <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Initial Lotsize : </p>
                                    <p class="text-gray-600 whitespace-no-wrap font-medium">{paramsData?.pc_initial_lotsize ? paramsData?.pc_initial_lotsize : "N/A"}</p>
                                  </div>
                                </td>
                              </tr>

                              {/* pc_tp_pips */}
                              <tr>
                                <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                  <div className="flex">
                                    <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> TP Pips : </p>
                                    <p class="text-gray-600 whitespace-no-wrap font-medium">{paramsData?.pc_tp_pips ? paramsData?.pc_tp_pips : "N/A"}</p>
                                  </div>
                                </td>
                                <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                  <div className="flex">
                                    <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Multiplier : </p>
                                    <p class="text-gray-600 whitespace-no-wrap font-medium">{paramsData?.pc_multiplier ? paramsData?.pc_multiplier : "N/A"}</p>
                                  </div>
                                </td>
                              </tr>

                              {/* pc_max_lotsize */}
                              <tr>
                                <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                  <div className="flex">
                                    <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> MAX Lotsize : </p>
                                    <p class="text-gray-600 whitespace-no-wrap font-medium">{paramsData?.pc_max_lotsize ? paramsData?.pc_max_lotsize : "N/A"}</p>
                                  </div>
                                </td>
                                <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                  <div className="flex">
                                    <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Gridsize : </p>
                                    <p class="text-gray-600 whitespace-no-wrap font-medium">{paramsData?.pc_gridsize ? paramsData?.pc_gridsize : "N/A"}</p>
                                  </div>
                                </td>
                              </tr>

                              {/* pc_max_lotsize */}
                              <tr>
                                <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                  <div className="flex">
                                    <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> PER : </p>
                                    <p class="text-gray-600 whitespace-no-wrap font-medium">{paramsData?.pc_per ? paramsData?.pc_per : "N/A"}</p>
                                  </div>
                                </td>
                                <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                  <div className="flex">
                                    <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Dev : </p>
                                    <p class="text-gray-600 whitespace-no-wrap font-medium">{paramsData?.pc_dev ? paramsData?.pc_dev : "N/A"}</p>
                                  </div>
                                </td>
                              </tr>

                              {/* pc_news_update_mins */}
                              <tr>
                                <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                  <div className="flex">
                                    <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Draw Panel : </p>
                                    <p class="text-gray-600 whitespace-no-wrap font-medium">{paramsData?.pc_draw_panel ? paramsData?.pc_draw_panel : "N/A"}</p>
                                  </div>
                                </td>
                                <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                  <div className="flex">
                                    <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> News Update Mins : </p>
                                    <p class="text-gray-600 whitespace-no-wrap font-medium">{paramsData?.pc_news_update_mins ? paramsData?.pc_news_update_mins : "N/A"}</p>
                                  </div>
                                </td>
                              </tr>

                              {/* pc_news_update_mins */}
                              <tr>
                                <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                  <div className="flex">
                                    <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Percent Close DD : </p>
                                    <p class="text-gray-600 whitespace-no-wrap font-medium">{paramsData?.pc_percent_close_dd ? paramsData?.pc_percent_close_dd : "N/A"}</p>
                                  </div>
                                </td>
                                <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                  <div className="flex">
                                    <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Stop Min Before : </p>
                                    <p class="text-gray-600 whitespace-no-wrap font-medium">{paramsData?.pc_stop_min_before ? paramsData?.pc_stop_min_before : "N/A"}</p>
                                  </div>
                                </td>
                              </tr>

                              {/* pc_start_min_after */}
                              <tr>
                                <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                  <div className="flex">
                                    <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Start Min After : </p>
                                    <p class="text-gray-600 whitespace-no-wrap font-medium">{paramsData?.pc_start_min_after ? paramsData?.pc_start_min_after : "N/A"}</p>
                                  </div>
                                </td>
                                <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                  <div className="flex">
                                    <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Impact Filter : </p>
                                    <p class="text-gray-600 whitespace-no-wrap font-medium">{paramsData?.pc_impact_filter ? paramsData?.pc_impact_filter : "N/A"}</p>
                                  </div>
                                </td>
                              </tr>

                              {/* pc_start_hour */}
                              <tr>
                                <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                  <div className="flex">
                                    <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Start Hour : </p>
                                    <p class="text-gray-600 whitespace-no-wrap font-medium">{paramsData?.pc_start_hour ? paramsData?.pc_start_hour : "N/A"}</p>
                                  </div>
                                </td>
                                <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                  <div className="flex">
                                    <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> End Hour : </p>
                                    <p class="text-gray-600 whitespace-no-wrap font-medium">{paramsData?.pc_end_hour ? paramsData?.pc_end_hour : "N/A"}</p>
                                  </div>
                                </td>
                              </tr>

                              {/* pc_max_spread */}
                              <tr>
                                <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                  <div className="flex">
                                    <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Max Spread : </p>
                                    <p class="text-gray-600 whitespace-no-wrap font-medium">{paramsData?.pc_max_spread ? paramsData?.pc_max_spread : "N/A"}</p>
                                  </div>
                                </td>
                                <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                  <div className="flex">
                                    <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Max Daily SL : </p>
                                    <p class="text-gray-600 whitespace-no-wrap font-medium">{paramsData?.pc_max_daily_sl ? paramsData?.pc_max_daily_sl : "N/A"}</p>
                                  </div>
                                </td>
                              </tr>

                              {/* pc_max_daily_profit */}
                              <tr>
                                <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                  <div className="flex">
                                    <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Max Daily Profit : </p>
                                    <p class="text-gray-600 whitespace-no-wrap font-medium">{paramsData?.pc_max_daily_profit ? paramsData?.pc_max_daily_profit : "N/A"}</p>
                                  </div>
                                </td>
                                <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                  <div className="flex">
                                    <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Max Month Profit : </p>
                                    <p class="text-gray-600 whitespace-no-wrap font-medium">{paramsData?.pc_max_month_profit ? paramsData?.pc_max_month_profit : "N/A"}</p>
                                  </div>
                                </td>
                              </tr>

                              {/* pc_use_news_filter */}
                              <tr>
                                <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                  <div className="flex">
                                    <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Use News Filter : </p>
                                    <p class="text-gray-600 whitespace-no-wrap font-medium">{paramsData?.pc_use_news_filter ? paramsData?.pc_use_news_filter : "N/A"}</p>
                                  </div>
                                </td>
                                <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                  <div className="flex">
                                    <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> News Close $ Value: </p>
                                    <p class="text-gray-600 whitespace-no-wrap font-medium">{paramsData?.pc_news_close_dollar_value ? paramsData?.pc_news_close_dollar_value : "N/A"}</p>
                                  </div>
                                </td>
                              </tr>

                              {/* pc_server_connection_sec */}
                              <tr>
                                <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                  <div className="flex">
                                    <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Server Connection Sec : </p>
                                    <p class="text-gray-600 whitespace-no-wrap font-medium">{paramsData?.pc_server_connection_sec ? paramsData?.pc_server_connection_sec : "N/A"}</p>
                                  </div>
                                </td>
                                <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                  <div className="flex">
                                    {/* <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> News Close $ Value: </p>
                                    <p class="text-gray-600 whitespace-no-wrap font-medium">{paramsData?.pc_news_close_dollar_value ? paramsData?.pc_news_close_dollar_value : "N/A"}</p> */}
                                  </div>
                                </td>
                              </tr>

                            </tbody>
                          </table>
                        </div>
                      </div>

                    </dd>
                    <div className="flex flex-wrap items-baseline justify-between gap-x-6 gap-y-2 bg-white px-1 sm:px-6 xl:px-8" >

                      <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">

                        <div class="lg:col-start lg:row-end-1">
                          {/* <h2 class="sr-only">Summary</h2> */}
                          <h2 class="text-base font-semibold leading-7 text-[#0172fd] mb-2">Summary</h2>
                          <div class="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
                            <dl class="flex flex-wrap">
                              <div class="flex-auto pl-6 pt-6">
                                <dt class="text-sm font-semibold leading-6 text-gray-800">Account No</dt>
                                <dd class="mt-1 text-base font-meduim leading-6 text-gray-600">{currentTab}</dd>
                              </div>
                              <div class="flex-auto pl-6 pt-6">
                                <dt class="text-sm font-semibold leading-6 text-gray-800">Account Name</dt>
                                <dd class="mt-1 text-base font-meduim leading-6 text-gray-600">{accInfo?.mua_acc_name}</dd>
                              </div>
                              <div class="flex-none self-end px-6 pt-4">
                                <dt class="sr-only">Status</dt>
                                {
                                  accStatus == 60 ? (
                                    <dd class="rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-600/20">Active</dd>
                                  ) : (accStatus == 24 ? (
                                    <dd class="rounded-md bg-orange-50 px-2 py-1 text-xs font-medium text-orange-600 ring-1 ring-inset ring-orange-600/20">Stopped</dd>
                                  ) : (
                                    <dd class="rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-600/20">In-Active</dd>
                                  ))
                                }

                              </div>
                              <div class="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-2 pt-2">

                                <table class="min-w-full">
                                  <tbody>

                                    <tr>
                                      <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                        <p class="text-gray-800 whitespace-no-wrap font-semibold">
                                          Server
                                        </p>
                                      </td>
                                      <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                        <p class="text-gray-600 whitespace-no-wrap font-meduim">
                                          {accInfo?.mua_acc_server}
                                        </p>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                        <p class="text-gray-800 whitespace-no-wrap font-semibold">
                                          Company
                                        </p>
                                      </td>
                                      <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                        <p class="text-gray-600 whitespace-no-wrap font-meduim">
                                          {accInfo?.mua_acc_company}
                                        </p>
                                      </td>
                                    </tr>

                                    {/* mua_acc_equity */}
                                    <tr>
                                      <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                        <div className="flex">
                                          <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Balance : </p>
                                          <p class="text-gray-600 whitespace-no-wrap font-medium">{accInfo?.mua_acc_balance}</p>
                                        </div>
                                      </td>
                                      <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                        <div className="flex">
                                          <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Equity : </p>
                                          <p class="text-gray-600 whitespace-no-wrap font-medium">{accInfo?.mua_acc_equity}</p>
                                        </div>
                                      </td>
                                    </tr>

                                    {/* mua_acc_deposits */}
                                    <tr>
                                      <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                        <div className="flex">
                                          <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Deposit : </p>
                                          <p class="text-gray-600 whitespace-no-wrap font-medium">{accInfo?.mua_acc_deposits}</p>
                                        </div>
                                      </td>
                                      <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                        <div className="flex">
                                          <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Credit : </p>
                                          <p class="text-gray-600 whitespace-no-wrap font-medium">{accInfo?.mua_acc_credit}</p>
                                        </div>
                                      </td>
                                    </tr>

                                    {/* mua_acc_margin_free */}
                                    <tr>
                                      <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                        <div className="flex">
                                          <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Margin : </p>
                                          <p class="text-gray-600 whitespace-no-wrap font-medium">{accInfo?.mua_acc_margin ? parseFloat(accInfo?.mua_acc_margin).toFixed(2) : "N/A"}</p>
                                        </div>
                                      </td>
                                      <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                        <div className="flex">
                                          <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Free Margin : </p>
                                          <p class="text-gray-600 whitespace-no-wrap font-medium">{accInfo?.mua_acc_margin_free ? parseFloat(accInfo?.mua_acc_margin_free).toFixed(2) : "N/A"}</p>
                                        </div>
                                      </td>
                                    </tr>

                                    {/* mua_acc_limit_orders */}
                                    <tr>
                                      <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                        <div className="flex">
                                          <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Margin Level : </p>
                                          <p class="text-gray-600 whitespace-no-wrap font-medium">{accInfo?.mua_acc_margin_level ? parseFloat(accInfo?.mua_acc_margin_level).toFixed(2) : "N/A"}</p>
                                        </div>
                                      </td>
                                      <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                        <div className="flex">
                                          <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Limit Orders : </p>
                                          <p class="text-gray-600 whitespace-no-wrap font-medium">{accInfo?.mua_acc_limit_orders}</p>
                                        </div>
                                      </td>
                                    </tr>

                                    {/* mua_acc_currency */}
                                    <tr>
                                      <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                        <div className="flex">
                                          <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Currency: </p>
                                          <p class="text-gray-600 whitespace-no-wrap font-medium">{accInfo?.mua_acc_currency}</p>
                                        </div>
                                      </td>
                                      <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                        <div className="flex">
                                          <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Leverage : </p>
                                          <p class="text-gray-600 whitespace-no-wrap font-medium">{accInfo?.mua_acc_leverage}</p>
                                        </div>
                                      </td>
                                    </tr>

                                    {/* buy pos - sell pos */}
                                    <tr>
                                      <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                        <div className="flex">
                                          <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Buy Position : </p>
                                          <p class="text-gray-600 whitespace-no-wrap font-medium">{accInfo?.mua_acc_buy_positions}</p>
                                        </div>
                                      </td>
                                      <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                        <div className="flex">
                                          <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Sell Position : </p>
                                          <p class="text-gray-600 whitespace-no-wrap font-medium">{accInfo?.mua_acc_sell_positions}</p>
                                        </div>
                                      </td>
                                    </tr>

                                    {/* totla bars - averrage range */}
                                    <tr>
                                      <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                        <div className="flex">
                                          <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Total Bars : </p>
                                          <p class="text-gray-600 whitespace-no-wrap font-medium">{accInfo?.mua_acc_total_bars}</p>
                                        </div>
                                      </td>
                                      <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                        <div className="flex">
                                          <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Average Range : </p>
                                          <p class="text-gray-600 whitespace-no-wrap font-medium">{accInfo?.mua_acc_average_daily_range}</p>
                                        </div>
                                      </td>
                                    </tr>

                                    {/* mua_acc_withdrawals - mua_acc_platform */}
                                    <tr>
                                      <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                        <div className="flex">
                                          <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Withdrawals : </p>
                                          <p class="text-gray-600 whitespace-no-wrap font-medium">{accInfo?.mua_acc_withdrawals}</p>
                                        </div>
                                      </td>
                                      <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                        <div className="flex">
                                          <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Platform : </p>
                                          <p class="text-gray-600 whitespace-no-wrap font-medium">{accInfo?.mua_acc_platform}</p>
                                        </div>
                                      </td>
                                    </tr>

                                    {/* mua_acc_winrate - mua_acc_profitfactor */}
                                    <tr>
                                      <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                        <div className="flex">
                                          <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Win Rate : </p>
                                          <p class="text-gray-600 whitespace-no-wrap font-medium">{accInfo?.mua_acc_winrate}</p>
                                        </div>
                                      </td>
                                      <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                        <div className="flex">
                                          <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Profit Factor : </p>
                                          <p class="text-gray-600 whitespace-no-wrap font-medium">{accInfo?.mua_acc_profitfactor}</p>
                                        </div>
                                      </td>
                                    </tr>

                                    {/* mua_acc_risksettings & mua_acc_martingalefactor */}
                                    <tr>
                                      <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                        <div className="flex">
                                          <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Risk Level : </p>
                                          <p class="text-gray-600 whitespace-no-wrap font-medium">{accInfo?.mua_acc_risksettings}</p>
                                        </div>
                                      </td>
                                      <td class="px-0 lg:px-3 py-3 border-b text-sm">
                                        <div className="flex">
                                          <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Martingale Factor : </p>
                                          <p class="text-gray-600 whitespace-no-wrap font-medium">{accInfo?.mua_acc_martingalefactor ? parseFloat(accInfo?.mua_acc_martingalefactor).toFixed(2) : "N/A"}</p>
                                        </div>
                                      </td>
                                    </tr>

                                    {/* mua_acc_maxlotsize & mua_acc_correlation */}
                                    <tr>
                                      <td class="px-0 lg:px-3 py-3 text-sm">
                                        <div className="flex">
                                          <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Max Lot Size : </p>
                                          <p class="text-gray-600 whitespace-no-wrap font-medium">{accInfo?.mua_acc_maxlotsize}</p>
                                        </div>
                                      </td>
                                      <td class="px-0 lg:px-3 py-3 text-sm">
                                        <div className="flex">
                                          <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Correlation/Coefficient : </p>
                                          <p class="text-gray-600 whitespace-no-wrap font-medium">{accInfo?.mua_acc_correlation}</p>
                                        </div>
                                      </td>
                                    </tr>

                                    {/* mua_acc_coefficient &  */}
                                    {/* <tr>
                                      <td class="px-0 lg:px-3 py-3 text-sm">
                                        <div className="flex">
                                          <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> Coefficient : </p>
                                          <p class="text-gray-600 whitespace-no-wrap font-medium">{accInfo?.mua_acc_coefficient}</p>
                                        </div>
                                      </td>
                                      <td class="px-0 lg:px-3 py-3 text-sm">
                                        <div className="flex">
                                          <p class="text-gray-900 whitespace-no-wrap font-semibold mr-2"> TITLEE : </p>
                                          <p class="text-gray-600 whitespace-no-wrap font-medium">{accInfo?.value}</p>
                                        </div>
                                      </td>
                                    </tr> */}

                                  </tbody>
                                </table>

                              </div>
                            </dl>
                            {/* <div class="mt-6 border-t border-gray-900/5 px-6 py-6">
                                    <a href="#" class="text-sm font-semibold leading-6 text-gray-900">Download Data <span aria-hidden="true">→</span></a>
                                </div> */}
                          </div>
                        </div>

                      </dd>
                    </div>
                  </dl>
                </div>
              </div>



            </div>



          </div>

          <dl className="mt-5 mb-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-6 md:divide-x md:divide-y-0">
            {three_stats.map((item) => (
              <div key={item.name} className="px-4 py-5 sm:p-6">
                <dt className="text-base font-normal text-gray-900">{item.name}</dt>
                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-[#0172fd]">
                    {item.stat}
                    {/* <span className="ml-2 text-sm font-medium text-gray-500">from {item.previousStat}</span> */}
                  </div>

                  {
                    item.changeType != "" ? (
                      <div
                        className={classNames(
                          item.changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                          'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0'
                        )}
                      >
                        {item.changeType === 'increase' ? (
                          <ArrowUpIcon
                            className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                            aria-hidden="true"
                          />
                        ) : (
                          <ArrowDownIcon
                            className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                            aria-hidden="true"
                          />
                        )}

                        <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
                        {item.change}
                      </div>
                    ) : null
                  }
                </dd>
              </div>
            ))}
          </dl>

          <div className="px-2 sm:px-6 lg:px-2 overflow-hidden ring-1 ring-black ring-opacity-5 sm:rounded-lg bg-gray-50 mb-5">
            <div className="p-4 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">

                  <span className="isolate inline-flex rounded-md shadow-sm">
                    <button
                      type="button"
                      className={` ${currentTable == 0 ? ' bg-gray-700 text-white ' : 'bg-white text-gray-700'} " relative inline-flex items-center rounded-l-md  px-3 py-2 text-sm font-semibold  ring-1 ring-inset ring-gray-300 focus:z-10"`}
                      onClick={(e) => {
                        setTable(0);
                      }}
                    >
                      Trade History
                    </button>
                    <button
                      type="button"
                      className={` ${currentTable == 1 ? ' bg-gray-700 text-white ' : 'bg-white text-gray-700'} " relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10"`}
                      onClick={(e) => {
                        setTable(1);
                      }}
                    >
                      Live Trades
                    </button>

                    {currentTable == 0 ? (
                      <Link
                        to={"#"}
                        onClick={() => handleExport('xlsx')}
                        type="button"
                        className="ml-2 inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff "
                      >
                        Download Excel
                      </Link>
                    ) : null}

                  </span>

                  {
                    currentTable == 0 ? (
                      <AccMetrixHistory uid={queryParams.get('uid')} name={queryParams.get('name')} accid={queryParams.get('accid')} />
                      // <Table
                      //   columns={
                      //     matrics_trade_history_columns({})
                      //   }
                      //   data={tradeHistory ? tradeHistory : []}
                      //   is_toggle={true}
                      //   isCustomPagination={isCustomPagination}
                      //   fetchData={getAllTradeHistory}
                      //   pageCount={pageCount}
                      //   customPageNo={customPageNo}
                      //   setCustomPageNo={setCustomPageNo}
                      // />
                    ) : (

                      <Table
                        columns={
                          matrics_trade_live_columns({})
                        }
                        data={tradeLive ? tradeLive : []}
                        is_toggle={true}
                        isCustomPagination={false}
                        fetchData={getAllMatrics}
                        pageCount={0}
                      />

                    )
                  }

                </div>
              </div>
            </div>
          </div>

          {/* Add Acc Config */}
          <Transition.Root show={open} as={Fragment}>
            <Dialog className="relative z-10" onClose={setOpen}>
              <div className="fixed inset-0" />

              <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                  <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                    <Transition.Child
                      as={Fragment}
                      enter="transform transition ease-in-out duration-500 sm:duration-700"
                      enterFrom="translate-x-full"
                      enterTo="translate-x-0"
                      leave="transform transition ease-in-out duration-500 sm:duration-700"
                      leaveFrom="translate-x-0"
                      leaveTo="translate-x-full"
                    >
                      <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            updateUserConfig(e);
                          }}
                          className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                          <div className="h-0 flex-1 overflow-y-auto">
                            <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                              <div className="flex items-center justify-between">
                                <Dialog.Title className="text-base font-semibold leading-6 text-white">
                                  Parameter Module - Details
                                </Dialog.Title>
                                <div className="ml-3 flex h-7 items-center">
                                  <button
                                    type="button"
                                    className="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                    onClick={() => setOpen(false)}
                                  >
                                    <span className="absolute -inset-2.5" />
                                    <span className="sr-only">Close panel</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                  </button>
                                </div>
                              </div>
                              <div className="mt-1">
                                <p className="text-sm text-indigo-300">
                                  Account: {accInfo ? accInfo?.license_account_map_relation?.lam_account_no : "N/A"}
                                </p>
                                <p className="text-sm text-indigo-300">
                                  Product: {accInfo ? accInfo?.license_account_map_relation?.master_whop_product_relation?.prod_name : "N/A"}
                                </p>
                                <p className="text-sm text-indigo-300">
                                  Name: {accInfo ? accInfo?.license_account_map_relation?.master_whop_user_relation?.user_name + " - " + accInfo?.license_account_map_relation?.master_whop_user_relation?.user_email : "N/A"}
                                </p>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="divide-y divide-gray-200 px-4 sm:px-6">
                                <div className="space-y-6 pb-5 pt-6">
                                  <div>
                                    <label
                                      htmlFor="description"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Config Data - (JSON/TEXT)
                                    </label>
                                    <div className="mt-2">
                                      <Editor
                                        options={{ autoIndent: true, formatOnType: true, formatOnPaste: true, language: 'json', autoClosingBrackets: true, inlineSuggest: true, automaticLayout: true, }}
                                        height="70vh"
                                        defaultLanguage="json"
                                        theme="vs-dark"
                                        defaultValue={configDataManual}
                                        onMount={handleEditorDidMount}
                                        className="border bg-slate-400"
                                      />

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-shrink-0 justify-end px-4 py-4">
                            <button
                              type="button"
                              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              onClick={() => setOpen(false)}
                            >
                              Cancel
                            </button>
                            <button
                              /* onClick={(e)=>{
                                updateUserConfig();
                              }} */
                              type="submit"
                              className="ml-4 inline-flex justify-center rounded-md bg-[#0172fd] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </div>



      ) : (

        <div>
          {/* <FallingLinesLoader /> */}
          <FSLoader isLoading={isLoading} title="Processing..." />
        </div>

      )}
    </>
  )
}

export default AccMetrix
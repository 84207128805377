import { Fragment, useState, useEffect } from "react";
import { Link, NavLink, Navigate, useNavigate } from "react-router-dom";
import { Dialog, Menu, Transition, Combobox } from "@headlessui/react";
import { ChevronDownIcon, PaintBrushIcon } from "@heroicons/react/20/solid";
import { ToastContainer, toast } from "react-toast";
import { BASEURL } from "../helper/constant";
import Select from "react-select";
import WhopServices from "../ApiServices/WhopServices";
import makeAnimated from 'react-select/animated';
import { decode as base64_decode, encode as base64_encode } from "base-64";
/* 
let encoded = base64_encode('YOUR_DECODED_STRING');
let decoded = base64_decode('YOUR_ENCODED_STRING');
 */

import {
  Bars3BottomLeftIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  UserIcon,
  MagnifyingGlassIcon,
  NewspaperIcon,
  CubeIcon,
  DocumentDuplicateIcon,
  ShieldCheckIcon,
  ShareIcon,
  ComputerDesktopIcon,
  UserGroupIcon,
  CurrencyDollarIcon,
  WindowIcon,
  TvIcon
} from "@heroicons/react/24/outline";
import { useDispatch } from "react-redux";
import { handleLogout } from "../redux/auth/actions";
import { useSelector } from "react-redux";
import { getLoginUserInfo } from "../redux/userInfo/actions";
import MasterServices from "../ApiServices/MasterServices";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DashboardLayout = ({ children }) => {

  const [scrollBg, setScrollBg] = useState(true);
  const [isSyncLoading, setSyncLoading] = useState(false);

  const animatedComponents = makeAnimated();
  const permissions = JSON.parse(localStorage.getItem("user_permissions"));
  const allModules = JSON.parse(localStorage.getItem("module"));

  useEffect(() => {
    const handleScroll = () => {
      // Calculate 20% of the screen height
      const twentyPercent = window.innerHeight * 0.03;

      // Check if the current scroll position is greater than 20% of the screen height
      if (window.scrollY > twentyPercent) {
        setScrollBg(false); // Remove background
      } else {
        setScrollBg(true); // Add background
      }
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  let FilterMenus = allModules?.map((module) => {
    const submenuItems = createSubmenuItems(module?.sys_module_ref);
    const menu = {
      module_id: module?.module_id,
      name: module?.module_name,
      href: module?.module_key,
      icon: getIconForModule(module?.module_name), // Replace with a function that maps module_name to an icon
      current: true, // You can set this based on your logic
      is_permitted: module?.module_is_active,
      submenuItems: createSubmenuItems(module?.sys_module_ref),
    };
    menu.submenu = submenuItems.length > 0 ? true : false;
    menu.current = true;
    return menu;
  });
  const uniqueArray = [];
  const uniqueModuleIds = new Set();
  

  if (FilterMenus?.length > 0) {
    FilterMenus?.forEach(module => {
      if (!uniqueModuleIds.has(module?.module_id)) {
        const matchingPermissions = permissions?.filter(permission => permission.fk_module_id === module.module_id);
        if (matchingPermissions?.length > 0) {
          uniqueModuleIds.add(module.module_id);

          const combinedObject = {
            name: module.name,
            href: module.href,
            icon: module.icon,
            current: true, // You can customize this based on your logic
            is_permitted: matchingPermissions[0].permission_is_active, // Assuming taking the first permission
            // Add other properties as needed
            submenuItems: module?.submenuItems,
            submenu: module?.submenu,
          };

          uniqueArray.push(combinedObject);
        }
      }
    });
  }

  function getIconForModule(moduleName) {
    switch (moduleName) {
      case "Dashboard":
        return HomeIcon;
      case "Users":
        return UsersIcon;
      case "Roles":
        return ShieldCheckIcon;
      case "Admin Users":
        return UserGroupIcon;
      case "Customers":
        return UsersIcon;
      case "Products":
        return CubeIcon;
      case "Licenses":
        return TvIcon;
      case "Payments":
        return CurrencyDollarIcon;
      case "Accounts":
        return ComputerDesktopIcon;
      // Add more cases as needed for other modules
      default:
        return CubeIcon; // Replace with a default icon
    }
  }

  const Menus = uniqueArray;
  // Function to create submenu items based on permissions

  function createSubmenuItems(permissions) {
    const submenuItems = [];

    if (permissions && permissions.length > 0) {
      permissions.forEach((permission) => {
        // Define submenu items based on permission name
        const permissionName = permission?.module_name;
        const permissionhref = permission?.module_key;
        if (permission.module_is_active) {
          const submenuItem = {
            name: permissionName,
            href: permissionhref,
            icon: ShieldCheckIcon,
            current: false, // Set this based on your logic
            is_permitted: permission.module_is_active,
          };

          submenuItems.push(submenuItem);
        }
      });
    }

    return submenuItems;
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sysModule, setSysModule] = useState([]);
  const {
    loginUserInfo: { userData },
  } = useSelector((state) => state);
  const [submenuOpen, setsubmenuOpen] = useState({
    appointment: false,
    forms: false,
    master: false,
    settings: false,
  });

  useEffect(() => {
    (async function () {
      const getSysModule = JSON.parse(localStorage.getItem("module"));
      getSysModule ? setSysModule(getSysModule) : setSysModule([]);
      dispatch(getLoginUserInfo("All"));
    })();
  }, []);

  const onLogout = () => {
    dispatch(handleLogout());
    Navigate("/login");
  };

  const handleProfile = () => {
    //console.log('nitesh');
  };

  const userNavigation = [
    { name: "Profile", href: "/profile", onclick: handleProfile, click: false },
    { name: "Logout", href: "#", onclick: onLogout, click: true },
  ];

  const [query, setQuery] = useState("");

  const [searchModal, setSearchModal] = useState(false);

  const filteredMenus =
    query === ""
      ? []
      : sysModule.filter((data) => {
        return data.module_name.toLowerCase().includes(query.toLowerCase());
      });

  const navigatePage = (routeData) => {
    setSearchModal(false);
    navigate(routeData.module_key);
  };

  const syncWhopData = async () => {
    try {
      setSyncLoading(true);
      const res = await WhopServices.fetchWhopData();
      if (res.status) {
        toast.success("Sync Successful");
      }else{
        toast.success("Sync Successful");
      }
      setSyncLoading(false);
    } catch (e) {
      toast.success("Sync Success");
      setSyncLoading(false);
    }
  }

  return (
    <>
      <ToastContainer delay={3000} position="top-right" />

      <div className="bg-gray-100">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gradient-to-b from-[#2e2e34] from-10%   via-gray-900 via-9%    to-gray-900 to-80% pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w-auto rounded-md"
                      src="logo.png"
                      alt="Korvato"
                    />
                    <h1 className="ml-2 items-center text-center text-white text-xl whitespace-nowrap">
                      Korvato
                    </h1>
                  </div>
                  <div className="mt-5 h-0 flex-1 overflow-y-auto">
                    <nav className="space-y-1 px-2">
                      {Menus.map((menu, index) =>
                        menu.is_permitted ? (
                          <div key={menu.name}>
                            <NavLink
                              to={menu.href}
                              // onClick={() => {
                              //   menu.submenu && setsubmenuOpen(!submenuOpen);
                              // }}
                              /* className={classNames(
                                menu.current
                                  ? "bg-cyan-800 text-white"
                                  : "text-cyan-100 hover:bg-cyan-600",
                                "group flex items-center px-2 py-2  font-medium rounded-md"
                              )} */
                              className={classNames(
                                window.location.pathname === menu.href
                                  ? "bg-gradient-to-b from-[#11ffdb] to-[#0172fd] text-white shadow-md"
                                  : "text-gray-100 hover:bg-gray-600",
                                "group flex items-center px-2 py-3 my-2 mx-1 font-medium rounded-md"
                              )}
                            >
                              <menu.icon
                                className="mr-3 h-6 w-6 flex-shrink-0 text-white"
                                aria-hidden="true"
                              />
                              {menu.name}
                              {menu.submenu && (
                                <ChevronDownIcon
                                  className={` ${submenuOpen && "-rotate-0"
                                    } mr-3 h-6 w-6 flex-shrink-0 -rotate-90 text-white duration-150`}
                                  aria-hidden="true"
                                />
                              )}
                            </NavLink>
                            {menu.submenu && (
                              <div>
                                {menu.submenuItems.map((submenuItem, index) =>
                                  submenuItem.is_permitted ? (
                                    <Link
                                      key={submenuItem.name}
                                      to={submenuItem.href}
                                      className={classNames(
                                        menu.current
                                          ? "bg-gradient-to-b from-[#11ffdb] to-[#0172fd] text-white"
                                          : "text-white hover:bg-cyan-600",
                                        "group flex items-center  px-2 py-2 text-xs font-normal rounded-md"
                                      )}
                                    >
                                      <submenuItem.icon
                                        className="ml-5 mr-3 h-5 w-6 flex-shrink-0 text-white"
                                        aria-hidden="true"
                                      />
                                      {submenuItem.name + " " + index}
                                    </Link>
                                  ) : null
                                )}
                              </div>
                            )}
                          </div>
                        ) : null
                      )}
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-grow flex-col overflow-y-auto m-2 rounded-lg bg-gradient-to-b from-[#2e2e34] from-10%   via-gray-900 via-9%    to-gray-900 to-80% pt-5">
            <div className="flex flex-shrink-0 items-center px-4">
              <img
                className="h-8 w-auto rounded-md"
                src="logo.png"
                alt="Korvato"
              />
              <h1 className="ml-2 items-center text-center text-white text-xl whitespace-nowrap">
                Korvato
              </h1>
            </div>
            <div className="mt-5 flex flex-1 flex-col">
              <nav className="flex flex-1 flex-col p-4">
                <ul role="list" className="flex flex-1 flex-col gap-y-1">
                  <li>
                    <ul role="list" className="space-y-2">
                      {Menus.map((menu, index) =>
                        menu.is_permitted ? (
                          <li key={menu.name}>
                            <Link
                              to={menu.href}
                              onClick={() => {
                                setsubmenuOpen({
                                  ...submenuOpen,
                                  [menu.name.toLowerCase()]:
                                    !submenuOpen[menu.name.toLowerCase()],
                                });
                              }}
                              className={classNames(
                                window.location.pathname === menu.href
                                  ? "bg-gradient-to-b from-[#11ffdb] to-[#0172fd] text-white shadow-md"
                                  : "text-gray-100 hover:bg-gray-600",
                                "group flex items-center px-2 py-2 my-1 mx-1 font-medium rounded-md "
                              )}
                            >
                              <menu.icon
                                className="mr-3 h-6 w-6 flex-shrink-0 text-white"
                                aria-hidden="true"
                              />
                              {menu.name}
                              {menu.submenu && (
                                <ChevronDownIcon
                                  className={` ${submenuOpen[menu.name.toLowerCase()]
                                    ? "-rotate-0"
                                    : "-rotate-90"
                                    } mr-3 h-6 w-6 flex-shrink-0 -rotate-90 text-gray-300 duration-150`}
                                  aria-hidden="true"
                                />
                              )}
                            </Link>
                            {menu.submenu &&
                              submenuOpen[menu.name.toLowerCase()] && (
                                <div>
                                  {submenuOpen &&
                                    menu.submenuItems.map(
                                      (submenuItem, index) =>
                                        submenuItem.is_permitted ? (
                                          <Link
                                            to={submenuItem.href}
                                            className={classNames(
                                              window.location.pathname ===
                                                submenuItem.href
                                                ? "bg-indigo-800 text-white"
                                                : "text-indigo-100 hover:bg-[#0172fd]",
                                              "group flex items-center px-2 py-2 font-medium rounded-md"
                                            )}
                                            key={submenuItem.name}
                                          >
                                            <submenuItem.icon
                                              className="ml-5 mr-3 h-5 w-6 flex-shrink-0 text-indigo-300"
                                              aria-hidden="true"
                                            />
                                            {submenuItem.name}
                                          </Link>
                                        ) : null
                                    )}
                                </div>
                              )}
                          </li>
                        ) : null
                      )}
                    </ul>
                  </li>
                 
                </ul>
              </nav>
            </div>
          </div>
        </div>

        {/* Main Section */}
        <div className="flex flex-1 flex-col md:pl-64 min-h-screen">

          {/* Header */}
          <div className={`sticky top-0 z-10 flex h-16 flex-shrink-0 shadow rounded-lg m-3 backdrop-blur-md ${scrollBg ? 'bg-white' : ''}`}>
            <button
              type="button"
              className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex flex-1 justify-between px-4">
              <div className="flex flex-1 items-center">
                <div className="w-8">
                  <MagnifyingGlassIcon
                    size={18}
                    className="cursor-pointer mr-2"
                    onClick={() => setSearchModal(true)}
                  />
                </div>

                {
                  isSyncLoading ? (

                    <div className="ml-2">
                      <button type="button" class="inline-flex items-center px-2 py-1 font-semibold leading-6 text-sm shadow rounded-md text-white bg-[#0172fd] transition ease-in-out duration-150 cursor-not-allowed" disabled>
                        <svg class="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Sync in progress...
                      </button>
                    </div>

                  ) : (

                    <div className="ml-2">
                      {localStorage.getItem("role_id") !== "4"?(<span class="relative inline-flex"
                        onClick={(e) => {
                          syncWhopData()
                        }}
                      >
                        <button type="button" class="rounded bg-slate-100 px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-5 transition ease-in-out duration-150 cursor-pointer leading-6">
                          Sync Whop Data
                        </button>
                        <span class="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                          <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#3d8beb] opacity-75"></span>
                          <span class="relative inline-flex rounded-full h-3 w-3 bg-[#0172fd]"></span>
                        </span>
                      </span>):null}
                    </div>

                  )
                }

                {/* CAN PUT CONTENT HERE INSTEAD OF SEARCH BAR */}
              </div>
              <div className="ml-4 flex items-center md:ml-6">
                <div className="text-end leading-none flex">
                  <p className="font-black text-sm mr-2">{userData?.user_first_name || ""} </p>
                  <div class="hidden md:block lg:block rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-green-700 bg-green-50 ring-green-600/20">
                  {(userData?.m_role_relation?.role_name || "")}
                  </div>
                </div>

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                      <span className="sr-only">Open user menu</span>
                      <span className="h-8 w-8 overflow-hidden rounded-full bg-gray-100">
                        {userData && userData.user_profile_image ? (
                          <img
                            src={userData.user_profile_image}
                            alt="profile_icon"
                          />
                        ) : (
                          <svg
                            className="h-full w-full text-gray-300"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                          </svg>
                        )}
                      </span>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item, key) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <Link
                              onClick={item.click ? item.onclick : null}
                              to={item.click ? null : item.href}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              {item.name}
                            </Link>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main>
            <div className="py-6">
              <div className="mx-auto max-w-[90rem] overflow-x-hidden px-2 sm:px-4 md:px-4">
                {children}
              </div>
            </div>
          </main>

          {/* Search Modal */}
          <Transition.Root
            show={searchModal}
            as={Fragment}
            afterLeave={() => setQuery("")}
            appear
          >
            <Dialog as="div" className="relative z-10" onClose={setSearchModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                    <Combobox
                      //onChange={(person) => (window.location = person.url)}
                      onChange={(person) => navigatePage(person)}
                    >
                      <div className="relative">
                        <MagnifyingGlassIcon
                          className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <Combobox.Input
                          className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                          placeholder="Search..."
                          onChange={(event) => setQuery(event.target.value)}
                        />
                      </div>

                      {filteredMenus.length > 0 && (
                        <Combobox.Options
                          static
                          className="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800"
                        >
                          {filteredMenus.map((person, key) => (
                            <Combobox.Option
                              key={person.module_name}
                              value={person}
                              className={({ active }) =>
                                classNames(
                                  "cursor-pointer select-none px-4 py-2",
                                  active && "bg-cyan-600 text-white"
                                )
                              }
                            >
                              {person.module_name}
                            </Combobox.Option>
                          ))}
                        </Combobox.Options>
                      )}

                      {query !== "" && filteredMenus.length === 0 && (
                        <p className="p-4 text-sm text-gray-500">
                          No search found.
                        </p>
                      )}
                    </Combobox>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;

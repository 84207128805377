/* eslint-disable */
import Api from "./Api";
// import AuthToken from "./AuthTokenServices";
export default {

  // Whop User Section Start
  async SaveWhopUser(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/whop-users`, payload);
    return response;
  },
  async getAllWhopUser(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/whop-users/?search=${payload}`);
    return response;
  },
  async getAllWhopSingleUser(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/whop-users/${payload}`);
    return response;
  },
  async setWhopUserStatus(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/whop-users/status`, payload);
    return response;
  },
  async deleteWhopUser(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/whop-users/remove`, payload);
    return response;
  },
  async whopUserExcelImport(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/whop-users/user-import`, payload);
    return response;
  },
  // Whop User Section End

  // Whop License Section Start
  async SaveWhopLicense(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/whop-license`, payload);
    return response;
  },
  async getAllWhopLicense(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/whop-license/?search=${payload}`);
    return response;
  },
  async getAllWhopSingleLicense(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/whop-license/${payload}`);
    return response;
  },
  async setWhopLicenseStatus(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/whop-license/status`, payload);
    return response;
  },
  async deleteWhopLicense(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/whop-license/remove`, payload);
    return response;
  },
  // Whop License Section End


  // Whop Product Section Start
  async SaveWhopProduct(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/whop-product`, payload);
    return response;
  },
  async getAllProductByUser(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/whop-product/by/user/?search=${payload}`);
    return response;
  },
  async getAllWhopProduct(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/whop-product/?search=${payload}`);
    return response;
  },
  async getAllWhopSingleProduct(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/whop-product/${payload}`);
    return response;
  },
  async setWhopProductStatus(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/whop-product/status`, payload);
    return response;
  },
  async deleteWhopProduct(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/whop-product/remove`, payload);
    return response;
  },
  // Whop Product Section End

  // Whop Payment Section Start
  async SaveWhopPayment(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/whop-payment`, payload);
    return response;
  },
  async getAllWhopPayment(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/whop-payment/?search=${payload}`);
    return response;
  },
  async getAllWhopSinglePayment(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/whop-payment/${payload}`);
    return response;
  },
  async setWhopPaymentStatus(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/whop-payment/status`, payload);
    return response;
  },
  async deleteWhopPayment(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/whop-payment/remove`, payload);
    return response;
  },
  // Whop Payment Section End


  // Whop Account Section Start
  async SaveWhopAccount(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/whop-account`, payload);
    return response;
  },
  async getAllWhopConnectedAccount(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/whop-account/all/connect/accounts/?search=${payload}`);
    return response;
  },
  async getAllWhopDashCount(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/whop-account/all/dash-count/?search=${payload}`);
    return response;
  },
  async getAllWhopAccount(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/whop-account/?search=${payload}`);
    return response;
  },
  async getAllWhopSingleAccount(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/whop-account/${payload}`);
    return response;
  },
  async setWhopAccountStatus(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/whop-account/status`, payload);
    return response;
  },
  async deleteWhopAccount(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/whop-account/remove`, payload);
    return response;
  },
  // Whop Account Section End

  // Whop Plan Section Start
  async SaveWhopPlan(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/whop-plan`, payload);
    return response;
  },
  async getAllWhopPlan(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/whop-plan/?search=${payload}`);
    return response;
  },
  async getAllWhopSinglePlan(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/whop-plan/${payload}`);
    return response;
  },
  async setWhopPlanStatus(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/whop-plan/status`, payload);
    return response;
  },
  async deleteWhopPlan(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/whop-plan/remove`, payload);
    return response;
  },

  // Whop Plan Section End


  // Whop Settings Section Start
  async SaveWhopSettings(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/setting`, payload);
    return response;
  },
  async getAllWhopSettings(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/setting/?search=${payload}`);
    return response;
  },
  async getAllWhopSingleSettings(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/setting/${payload}`);
    return response;
  },
  async setWhopSettingsStatus(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/setting/status`, payload);
    return response;
  },
  async deleteWhopSettings(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/setting/remove`, payload);
    return response;
  },

  // Whop Settings Section End

  // Sync Whop Data
  async fetchWhopDataBak() {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/whop-common/new`);
    return response;
  },

  async fetchWhopData() {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/whop-common/fast`);
    return response;
  },


  //Update Config
  async updateConfigData(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/whop-common/update-config`, payload);
    return response;
  }

};
